<div class="header">
  <div class="info-container">
    <div class="row mx-0 desktopMenu hide-xs hide-vs hide-sm">
      <div class="col-3 p-1 logoWrapper">
        <div class="logo verticalCenterRow p-relative">
          <a [routerLink]="userHasService?'home':'auth/signup'"
            ><img
              routerLinkActive="router-link-active"
              src="{{cloudUrl}}/images/soundblock-logo.png"
          /></a>
          <span class="beta gradientText">BETA</span>
        </div>
      </div>
      <div class="col-9 p-1 menu">
        <div class="menu-header">
          <ul id="menu-main-nav" class="navbar-nav">
            <div
              routerLink="account"
              class="menu-item"
              *ngIf="authService.isAuthorized && userHasService"
            >
              <i class="icon-colors-2 fad fa-user-cog"></i>
              <div class="menu-name pt-0">
                <span>ACCOUNT</span>
              </div>
            </div>
            <div
              routerLink="profile"
              class="menu-item hide-md"
              *ngIf="authService.isAuthorized && userHasService"
            >
              <i class="icon-colors fad fa-user-crown"></i>
              <div class="menu-name pt-0">
                <span>PROFILE</span>
              </div>
            </div>
            <div
              routerLink=""
              class="menu-item"
              *ngIf="authService.isAuthorized && userHasService"
            >
              <i class="icon-colors-2 fad fa-user-music"></i>
              <div class="menu-name pt-0">
                <span>PROJECTS</span>
              </div>
            </div>
            <div
              routerLink="reports"
              class="menu-item"
              *ngIf="authService.isAuthorized && userHasService && !hasFraudAccount"
            >
              <i class="icon-colors fad fa-user-chart"></i>
              <div class="menu-name pt-0">
                <span>REPORTS</span>
              </div>
            </div>
            <div
              (click)="showHideTicketbar()"
              class="menu-item"
              *ngIf="authService.isAuthorized"
            >
              <i class="icon-colors fad fa-user-headset"></i>
              <div class="menu-name pt-0">
                <span class="position-relative">SUPPORT </span>
              </div>
              <div
                *ngIf="ticketNotifications"
                class="px-2 py-1 text-white start-100 badge rounded-pill support-link-bg square-badge ticket-notification"
              >
                <span>{{ticketNotifications}}</span>
              </div>
            </div>
            <div
              routerLink="auth/signin"
              class="menu-item"
              *ngIf="!authService.isAuthorized"
            >
              <i class="icon-colors fad fa-sign-in-alt"></i>
              <div class="menu-name pt-0">
                <span>Sign In</span>
              </div>
            </div>
            <div
              routerLink="auth/signup"
              class="menu-item"
              *ngIf="!authService.isAuthorized"
            >
              <i class="icon-colors-2 fad fa-user-plus"></i>
              <div class="menu-name pt-0">
                <span>Sign Up</span>
              </div>
            </div>
          </ul>
          <div
            #userMenuPopover="bs-popover"
            class="avatar"
            *ngIf="authService.isAuthorized"
            [popover]="userMenu"
            container="body"
            [outsideClick]="true"
            placement="bottom right"
          >
            <img [src]="user.avatar" />
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 mobileMenu hide-md hide-lg hide-vl hide-xl">
      <div class="col-2 p-1 logoWrapper">
        <div class="logo p-relative">
          <a routerLink="home"
            ><img
              routerLinkActive="router-link-active"
              src="{{cloudUrl}}/images/soundblock-logo.png"
          /></a>
          <span class="beta-mobile gradientText">BETA</span>
        </div>
      </div>

      <div class="menu col-10">
        <div
          role="button"
          *ngIf="authService.isAuthorized && !userHasService"
          class="menuItem me-2"
          (click)="signOut()"
        >
          <i class="icon-colors fad fa-sign-out-alt me-1"></i><span>Sign Out</span>
        </div>
        <div
          class="icon me-2"
          (click)="showUsermenu($event)"
          *ngIf="authService.isAuthorized && userHasService"
        >
          <img [src]="user.avatar" />
        </div>

        <div
          (click)="showHideTicketbar()"
          class="menu-item hide-vs hide-xs me-vs-1 me-sm-3"
          *ngIf="authService.isAuthorized"
          style="position: relative"
        >
          <span><i class="icon-colors fad fa-user-headset"></i></span>
          <div
            *ngIf="ticketNotifications"
            class="ps-2 pt-1 pb-1 pe-2 text-white top-0 start-100 badge rounded-pill support-link-bg square-badge"
          >
            <span>{{ticketNotifications}}</span>
            <div class="visually-hidden">unread messages</div>
          </div>
        </div>

        <div
          routerLink="reports"
          class="menu-item hide-vs hide-xs me-vs-1 me-sm-3"
          *ngIf="authService.isAuthorized && !hasFraudAccount"
        >
          <span><i class="icon-colors fad fa-user-chart"></i></span>
        </div>

        <div
          routerLink="projects"
          class="menu-item hide-vs hide-xs me-vs-1 me-sm-3"
          *ngIf="authService.isAuthorized && userHasService"
        >
          <span><i class="icon-colors-2 fad fa-user-music"></i></span>
        </div>

        <div
          routerLink="profile"
          class="menu-item hide-vs hide-xs me-vs-1 me-sm-3"
          *ngIf="authService.isAuthorized && userHasService"
        >
          <span><i class="icon-colors fad fa-user-crown"></i></span>
        </div>

        <div
          routerLink="account"
          class="menu-item hide-vs hide-xs me-vs-1 me-sm-3"
          *ngIf="authService.isAuthorized && userHasService"
        >
          <span
            ><i
              class="icon-colors-2 fad fa-user-cog"
              nbTooltip="Account"
              nbTooltipPlacement="top"
              nbTooltipStatus="info"
            ></i
          ></span>
        </div>
        <div routerLink="auth/signup" class="menu-item" *ngIf="!authService.isAuthorized">
          <span><i class="icon-colors-2 fad fa-user-plus me-1"></i>Sign Up</span>
        </div>
        <div
          routerLink="auth/signin"
          class="menu-item me-3"
          *ngIf="!authService.isAuthorized"
        >
          <span><i class="icon-colors fad fa-sign-in-alt me-1"></i>Sign In</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #userMenu>
  <div class="userMenu card">
    <div class="card-header">
      <span>{{ user.name }}</span>
    </div>
    <div class="card-body">
      <div
        *ngIf="authService.isAuthorized && userHasService"
        class="menuItem"
        (click)="navigatePage('profile')"
      >
        <i class="icon-colors fad fa-user-crown"></i><span>Profile</span>
      </div>
      <!-- <div
        *ngIf="authService.isAuthorized && userHasService"
        class="menuItem"
        (click)="showNotification()"
      >
        <i class="icon-colors fad fa-bells"></i><span>Notifications</span>
        <div class="badge" *ngIf="user.unread_notifications_count > 0">
          <span>{{ user.unread_notifications_count }}</span>
        </div>
      </div> -->
      <div *ngIf="authService.isAuthorized" class="menuItem" (click)="signOut()">
        <i class="icon-colors fad fa-sign-out-alt"></i><span>Sign Out</span>
      </div>
    </div>
  </div>
</ng-template>
