import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  OnChanges,
  ElementRef,
} from '@angular/core';
import { Subject } from 'rxjs';

import { Ticket, TicketMessage } from 'src/app/models/ticket';
import { ProfileService } from 'src/app/services/account/profile';
import { TicketService } from 'src/app/services/support/ticket';
import { PanelService } from 'src/app/services/shared/panel';
import { first, takeUntil } from 'rxjs/operators';
import { KeyValue } from '@angular/common';
import { NgForm } from '@angular/forms';
import Pusher from 'pusher-js';
import { environment } from 'src/environments/local';
import { AuthService } from 'src/app/services/account/auth';
import { Router, ActivatedRoute } from '@angular/router';
export enum TicketFilters {
  'Open' = 'open',
  'Closed' = 'closed',
  'Awaiting Customer' = 'awaiting customer',
  'Awaiting Support' = 'awaiting support',
}

@Component({
  selector: 'app-ticket-sidebar',
  templateUrl: './ticketbar.component.html',
  styleUrls: ['./ticketbar.component.scss'],
})
export class TicketSidebarComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('ticketMessagesIonContent') ticketMessagesIonContent: ElementRef;
  @ViewChild('scroll') scroll: ElementRef;
  @ViewChild('ticketsIonContent') ticketsIonContent: any;

  @Input() technicalSupport: any;
  private destroy$ = new Subject<void>();
  currentUser: any;
  ticket: Ticket;

  channel: any;
  message: string;
  ticket_title: string;
  support_type: string;
  file: any;
  error: any;
  isW9Form: boolean;

  ticketPage = 1;
  ticketFilter: TicketFilters;
  totalTickets: number;
  tickets: Ticket[] = [];
  ticketsLoaded: boolean;
  msgLoaded: boolean;
  invalidMessage: boolean;
  invalidFileExtension: boolean;

  ticketMessagesPage = 1;
  totalTicketMessages: number;
  ticketMessages: TicketMessage[] = [];
  ticketCreating = false;

  pageStatus = 0;

  ticketbarVisible = false;
  currentUrlSegment: string;
  ticketTypes = ['Customer Service', 'Technical Support', 'Feedback'];

  scrollDistance = 0.1;
  loading = false;

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  };

  constructor(
    private ticketService: TicketService,
    private panelService: PanelService,
    private profileService: ProfileService,
    private authService: AuthService,
    private router: ActivatedRoute,
    private routing: Router
  ) {}

  ngOnInit() {
    this.getCurrentUrlParams();
    this.getCurrentUser();
    this.getTickets();
    this.watchTicketbarVisible();
  }

  ngOnChanges() {
    if (this.technicalSupport != null) {
      this.showTicketPage();
      this.ticket_title = this.technicalSupport.subject;
      this.support_type = this.technicalSupport.type;
      this.message = this.technicalSupport.message;
      this.isW9Form = this.technicalSupport.isW9Form;
    }
  }

  setMessage(event) {
    this.invalidMessage = false;
  }

  watchTicketbarVisible() {
    this.panelService
      .getTicketbarVisible()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.ticketbarVisible = res;
      });
  }

  getCurrentUrlParams() {
    this.routing.events.subscribe((val) => {
      const urlSegs = this.routing.url.split('?');
      const pathSegs = urlSegs[0].split('/').filter((value) => value !== '');
      this.currentUrlSegment = pathSegs[0];
    });

    if (this.currentUrlSegment === 'support') {
      this.panelService.setTicketbarVisible(!this.ticketbarVisible);
    }
    this.router.queryParams.subscribe((params) => {
      if (params.ticket_id) {
        try {
          this.routing.navigate(['/']);
          this.ticketService
            .getTicket(params.ticket_id)
            .pipe(first())
            .subscribe((ticket) => {
              if (ticket.data) {
                this.openTicket(ticket.data);
              }
            });
        } catch (error) {
          this.panelService.setTicketbarVisible(false);
          this.routing.navigate(['/']);
        }
      }
    });
  }

  openTicket(ticket: Ticket) {
    this.ticket = ticket;
    this.resetAndLoadTicketMessages();
    this.pageStatus = 1;
    this.message = '';
    this.file = null;
    this.subscribeToTicketChannel();
  }

  attachFile(files: FileList) {
    this.file = files[0];
  }

  sendMessage() {
    if (!this.message || (this.file && !this.message)) {
      this.invalidMessage = true;
      return;
    }

    this.ticketService
      .addMessage(
        this.ticket.ticket_uuid,
        this.currentUser.user_uuid,
        this.message.replace(/(\n)+/g, '<br />'),
        this.file ? [this.file] : []
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.resetAndLoadTicketMessages();

          this.invalidFileExtension = false;
          this.invalidMessage = false;
          this.message = '';
          this.file = null;
        },
        (err) => {
          this.error = err.error.errors.files[0];
          this.invalidFileExtension = true;
        }
      );
  }

  backToTicket() {
    this.ticket = null;
    this.pageStatus = 0;
    this.invalidMessage = false;
    this.invalidFileExtension = false;
  }

  replaceText(message) {
    return message.replace(/(\n)+/g, '<br />');
  }

  scrollToBottom() {
    try {
      this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
    } catch (e) {}
  }

  showTicketPage() {
    this.support_type = '';
    this.ticket_title = '';
    this.message = '';
    this.file = null;
    this.pageStatus = 2;
  }

  createTicket(form: NgForm) {
    if (!form.valid) {
      return;
    }
    this.ticketCreating = true;
    const ticketData = {
      supportType: this.support_type,
      ticketTitle: this.ticket_title,
      message: this.message,
      file: this.file,
      isW9Form: this.isW9Form,
      userId: this.currentUser.user_uuid,
    };

    this.ticketService
      .addTicket(ticketData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.ticketPage = 1;
          this.tickets = [];
          this.getTickets();

          if (res) {
            this.openTicket(res);
          }
          this.backToTicket();
          this.message = '';
          this.file = null;
          this.ticketCreating = false;
        },
        (err) => {
          if (err.message) this.error = err.message;
          else this.error = err.error.status.message;
          this.invalidFileExtension = true;
          this.ticketCreating = false;
        }
      );
    if (this.ticket_title == 'W-9 Form Submission') {
      this.ticketService.ChangeTicketStatusToPending().subscribe();
      this.ticketService.w9StatusSubmitted.next(true);
    }
  }

  download(file) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', file.attachment_url);
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  showTicketbar() {
    this.ticket = null;
    // this.routing.navigate(['/']);
    this.panelService.setTicketbarVisible(!this.ticketbarVisible);
    //  this.routing.navigate(['/']);
  }

  closeDialog(ref) {
    ref.close();
  }

  async loadNextTicketMessagesPage(event: any) {
    if (this.ticketMessages.length < this.totalTicketMessages) {
      this.ticketMessagesPage++;
      this.getTicketMessages();
    }
  }

  async loadNextTicketsPage(event: any) {
    if (this.tickets.length < this.totalTickets) {
      this.ticketPage++;
      this.getTickets();
    }
  }

  private getTicketMessages() {
    if (!this.ticket || !this.ticket.ticket_uuid) {
      if (!this.channel) return;

      this.channel.unbind_all();
      this.channel.disconnect();
      this.channel.unsubscribe();
      return;
    }

    this.msgLoaded = false;
    this.ticketService
      .getTicketMessages(this.ticket.ticket_uuid, this.ticketMessagesPage)
      .pipe(first())
      .subscribe((res) => {
        this.totalTicketMessages = res.total;
        if (this.ticketMessages.length < this.totalTicketMessages) {
          this.ticketMessages.push(...res.data);
          this.ticketMessages.sort((a, b) => {
            // @ts-ignore
            return new Date(a.stamp_created * 1000) - new Date(b.stamp_created * 1000);
          });
          this.ticketMessages.map((ticketMessage) => {
            return (ticketMessage.message_text = this.getMessageLink(
              ticketMessage.message_text
            ));
          });
          this.msgLoaded = true;
          setTimeout(() => this.scrollToBottom(), 0);
        }
      });
  }

  private getTickets() {
    this.ticketsLoaded = false;
    this.ticketService
      .getSupportTickets(this.ticketPage, 10, this.currentUser.user_uuid)
      .pipe(first())
      .subscribe((res) => {
        this.ticketsLoaded = true;
        this.totalTickets = res.total;
        if (this.tickets.length < this.totalTickets) {
          this.tickets.push(...res.data);
        }
      });
  }

  private getCurrentUser() {
    this.profileService
      .getBasicUserInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.currentUser = res;
      });
  }

  private styleIonContent(ionContent: any) {
    if (!ionContent) {
      return;
    }

    const stylesheet = `
      ::-webkit-scrollbar-track {
        background: #f7f9fc;
      }

      ::-webkit-scrollbar-thumb {
        background: #e4e9f2;
      }

      ::-webkit-scrollbar {
        width: 8px;
        background-color: #f7f9fc;
      }
    `;

    const styleElmt = ionContent.el.shadowRoot.querySelector('style');

    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      ionContent.el.shadowRoot.appendChild(barStyle);
    }
  }

  private resetAndLoadTicketMessages() {
    this.ticketMessages = [];
    this.ticketMessagesPage = 1;
    this.getTicketMessages();
  }

  private subscribeToTicketChannel() {
    const pusher = new Pusher(environment.pusherApiKey, {
      authEndpoint: `${environment.apiUrl}broadcasting/auth`,
      cluster: environment.pusherCluster,
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
      encrypted: true,
      auth: {
        params: {},
        headers: {
          Authorization: `Bearer ${this.authService.accessToken}`,
        },
      },
    });

    this.channel = pusher.subscribe(
      `private-channel.app.soundblock.support.ticket.${this.ticket?.ticket_uuid}`
    );

    this.channel.bind(`Soundblock.Support.Ticket.${this.ticket?.ticket_uuid}`, (res) => {
      this.getTicketMessages();
    });
  }
  getMessageLink(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
