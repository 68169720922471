<footer class="main-footer">
  <div class="container">
    <div class="row main-block">
      <div class="col-xl-6 col-lg-4 col-sm-12 col-12 p-1 hide-vl hide-xl hide-lg">
        <div class="footer-logo">
          <a href="javascript:;">
            <img
              class="transition-action"
              src="{{cloudUrl}}/images/soundblock-logo-box.png"
            />
          </a>
          <ul class="footer-menu">
            <li><a class="transition-action" href="javascript:;">Terms & Services</a></li>
            <li><a class="transition-action" href="javascript:;">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-sm-12 col-12 col-md-6 p-1">
        <div class="register-form">
          <div class="social-sites">
            <div class="title">FOLLOW US:</div>
            <i class="fab fa-facebook-f icon"></i>
            <i class="fab fa-instagram icon"></i>
            <i class="fab fa-youtube icon"></i>
            <i class="fab fa-google icon"></i>
            <i class="fab fa-linkedin-in icon"></i>
            <i class="fab fa-twitter icon"></i>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-4 col-sm-12 col-12 p-1 hide-vs hide-xs hide-sm hide-md">
        <div class="footer-logo">
          <a href="javascript:;">
            <img
              class="transition-action"
              src="{{cloudUrl}}/images/soundblock-logo-box.png"
            />
          </a>
          <ul class="footer-menu">
            <li><a class="transition-action" href="javascript:;">Terms & Services</a></li>
            <li><a class="transition-action" href="javascript:;">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-sm-12 col-12 col-md-6">
        <div class="contact-info">
          <div class="contact-wrapper">
            <p class="address">
              SOUNDBLOCK, LLC
              <br />2375 East Camelback Road, 6th Floor <br />Phoenix, AZ 85016
            </p>
            <a class="phone-number contact-link transition-action" href="tel:602-466-9636"
              >602-466-9636</a
            >
            <a
              class="email contact-link transition-action"
              href="mailto:contact@soundblock.com"
              >contact@soundblock.com</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 copyright">
      <div class="col-12 p-1">
        <span>
          © {{currentYear}} Simple Machine, LLC and affiliated companies. All rights
          reserved. Arena, arena.com, Arena Music, arenamusic.com, ARENX, arenx.com,
          Soundblock, soundblock.com, Musicoin, musicoin.com and "Fair Pay. Free Play."
          are registered trademarks of Simple Machine, LLC.</span
        >
      </div>
    </div>
  </div>
</footer>
