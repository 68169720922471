import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-delete-confirmation',
  template: `
    <div class="confirmDialogBody">
      <div class="modal-header" modalDraggable>
        <span class="me-auto"> {{ title }} </span>
        <button (click)="cancel()" type="button">
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
      </div>
      <div class="modal-body cardContent centered">
        <div class="row container m-0">
          <div class="col-12 message p-1">{{ message }}</div>
        </div>
        <div class="d-inline-flex justify-content-around full-width">
          <button
            class="btn btn-gradient btn-large m-2"
            shape="semi-round"
            fullWidth
            (click)="confirm()"
          >
            Confirm
          </button>
          <button
            class="btn btn-outline-gradient btn-large m-2"
            shape="semi-round"
            fullWidth
            (click)="cancel()"
          >
            <span class="gradientText">Cancel</span>
          </button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['../../../components/settings/account/holder/holder.component.scss'],
})
export class DeleteConfirmationComponent {
  @Input() message: string;
  @Input() title = 'Delete Confirmation';

  @Output() confirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private modalService: BsModalService) {}

  cancel() {
    this.modalService.hide();
  }

  confirm() {
    this.confirmed.next(true);
    this.modalService.hide();
  }
}
