import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';

import { NotificationService } from '../support/notification';
import {
  Service,
  ServiceData,
  ServiceWithProjects,
  Language,
  ContributorType,
  Genre,
  ProjectFormat,
  ProjectRole,
  DeleteArtistRequest,
  AddArtistRequest,
  EditArtistRequest,
  EditArtistPublisher,
  DeleteArtistPublisherRequest,
  AddArtistPublisherRequest,
  PlanTypes,
  AddContributorRequest,
  DeleteContributorRequest,
  EditContributorRequest,
  InvitesResponse,
  EmailInvite,
} from 'src/app/models/service';
import { User } from 'src/app/models/user';
import {
  AddressInfo,
  BankingInfo,
  EmailInfo,
  PaymethodInfo,
  PhoneInfo,
  Profile,
  EmailInfoResponse,
  PhoneInfoResponse,
  AddressInfoResponse,
  PaymentInfo,
} from 'src/app/models/profile';
import { AccountData } from 'src/app/models/account';
import { Platform } from 'src/app/models/platform';
import { ArtistPublisherResponse } from 'src/app/models/project';
import { AddTrackContributorRequest } from 'src/app/models/collection';
import { BroadcastService } from '../user-broadcast/broadcast.service';
import { Payment } from '../../models/draft';
import * as moment from 'moment';
import { TeamData, TeamProjects } from '../../models/team';

export interface BootloaderData {
  invited_accounts: Service[];
  permitted_accounts: Service[];
  accounts: ServiceData[];
  user: User;
  unread_messages: number;
  user_earnings: any;
  user_balance: number;
}

export class BootLoaderServiceData {
  languages: Language[];
  contributorTypes: ContributorType[];
  genres: Genre[];
  platforms: Platform[];
  projectFormats: ProjectFormat[];
  projectRoles: ProjectRole[];

  constructor(input: any) {
    this.languages = input.languages.map((language) => new Language(language));
    this.contributorTypes = input.contributor_types.map(
      (contributor) => new ContributorType(contributor)
    );
    this.genres = input.genres.map((genre) => new Genre(genre));
    this.platforms = input.platforms;
    this.projectFormats = input.project_formats.map(
      (format) => new ProjectFormat(format)
    );
    this.projectRoles = input.project_roles.map((role) => new ProjectRole(role));
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  profile: BehaviorSubject<any>;
  user: BehaviorSubject<User>;
  services: BehaviorSubject<ServiceData[]>;
  languages: BehaviorSubject<Language[]>;
  contributorTypes: BehaviorSubject<ContributorType[]>;
  genres: BehaviorSubject<Genre[]>;
  platforms: BehaviorSubject<Platform[]>;
  projectFormats: BehaviorSubject<ProjectFormat[]>;
  projectRoles: BehaviorSubject<ProjectRole[]>;
  allProjects: BehaviorSubject<TeamProjects[]>;
  inviteServices: Service[];
  inviteServices$: BehaviorSubject<(ServiceWithProjects | EmailInvite)[]> = new BehaviorSubject<(ServiceWithProjects | EmailInvite)[]>([]);
  planTypes: BehaviorSubject<PlanTypes[]>;
  earnings: BehaviorSubject<any>;
  userBalance: BehaviorSubject<number | string> = new BehaviorSubject(0);
  isSupportedCountry$: Subject<boolean> = new Subject<boolean>();
  userHasService: boolean;
  userEmailVerified: boolean;

  childLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private broadcastService: BroadcastService
  ) {
    this.user = new BehaviorSubject({} as User);
    this.services = new BehaviorSubject([]);
    this.profile = new BehaviorSubject({});
    this.languages = new BehaviorSubject([]);
    this.platforms = new BehaviorSubject([]);
    this.projectFormats = new BehaviorSubject([]);
    this.projectRoles = new BehaviorSubject([]);
    this.allProjects = new BehaviorSubject([]);
    this.genres = new BehaviorSubject([]);
    this.contributorTypes = new BehaviorSubject([]);
    this.earnings = new BehaviorSubject({});
    this.userBalance = new BehaviorSubject(0);
  }

  bootLoader() {
    return this.http
      .get<{
        data: BootloaderData;
        status: any;
      }>(`soundblock/bootloader`)
      .pipe(
        map((response) => {
          this.parseBootloadData(response.data);
          return response.data;
        })
      );
  }

  bootLoaderData() {
    return this.http
      .get<{
        data: BootLoaderServiceData;
        status: any;
      }>(`soundblock/bootloader-data`)
      .pipe(
        map((response) => {
          this.parseBootLoaderServiceData(new BootLoaderServiceData(response.data));
          return response.data;
        })
      );
  }

  parseBootLoaderServiceData(data: BootLoaderServiceData) {
    this.contributorTypes.next(data.contributorTypes);
    this.genres.next(data.genres);
    const dashes = [
      { dataCode: ' - - - ', dataLanguage: ' - - - ', disabled: true },
    ] as any;
    const topLanguages = data.languages.filter(
      (language) =>
        language.dataLanguage === 'Spanish' || language.dataLanguage === 'English'
    );
    const otherLanguages = data.languages.filter(
      (language) => topLanguages.indexOf(language) === -1
    );
    this.languages.next([...topLanguages, ...dashes, ...otherLanguages]);
    this.projectFormats.next(data.projectFormats);
    this.platforms.next(data.platforms);
    this.projectRoles.next(data.projectRoles);
  }
  parseBootloadData(data: BootloaderData) {
    this.earnings.next(data.user_earnings);
    this.userBalance.next(data.user_balance);
    this.user.next(data.user);
    this.services.next(data.accounts);
    this.inviteServices = data.invited_accounts;
    this.broadcastService.setTicketMessagesCount(data.unread_messages);

    const setting = data.user.notification_setting.setting;

    this.userHasService = !!data.accounts.length || !!data.permitted_accounts.length;
    this.userEmailVerified = !!data.user.primary_email.flag_verified;

    this.notificationService.updateSettings(setting);
  }

  getChildLoadingStatus() {
    return this.childLoaded$.asObservable();
  }

  setChildLoadingStatus(status) {
    this.childLoaded$.next(status);
  }

  getUserProfile() {
    return this.http
      .get<{
        data: Profile;
        status: any;
      }>(`user/profile`)
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  getPlanTypes(): Observable<PlanTypes[]> {
    return this.http.get<{ data: PlanTypes[], meta: { flag_supportedcountry: boolean } }>(`soundblock/account_plans_types`).pipe(
      map((res) => {
        this.isSupportedCountry$.next(res?.meta?.flag_supportedcountry);
        return res.data.map((planType) => new PlanTypes(planType));
      })
    );
  }

  getUserInviteServices() {
    return this.http
      .get<{
        data: InvitesResponse;
        status: any;
      }>(`soundblock/invites/accounts`)
      .pipe(
        map((res) => [...res.data.account, ...res.data.email]),
        tap((res) => this.inviteServices$.next(res))  
      );
  }

  acceptServiceInvite(accountUUID: string) {
    return this.http.post<any>(`soundblock/invite/account/${accountUUID}`, {});
  }

  rejectServiceInvite(accountUUID: string) {
    return this.http.delete<any>(`soundblock/invite/account/${accountUUID}`);
  }

  acceptEmailInvite(inviteUUID: string) {
    return this.http.post<any>(`soundblock/invite/account/email/${inviteUUID}`, {});
  }

  rejectEmailInvite(inviteUUID: string) {
    return this.http.delete<any>(`soundblock/invite/account/email/${inviteUUID}`);
  }

  getAccount() {
    return this.http
      .get<{
        data: AccountData;
      }>(`soundblock/setting/account`)
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  getServiceInfo(uuid) {
    return this.http.get<any>(`soundblock/account/${uuid}`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getOverdueData(uuid) {
    return this.http.get<any>(`soundblock/account/${uuid}/plan/past_due`).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  updateOverdue(uuid, paymentId) {
    return this.http
      .post<any>(`soundblock/account/${uuid}/plan/past_due`, { payment_id: paymentId })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getUserServices() {
    return this.http.get<any>(`soundblock/accounts/user/plans`).pipe(
      map((res) => {
        this.isSupportedCountry$.next(res?.meta?.flag_supportedcountry);
        return res.data;
      })
    );
  }

  getMonthlyReport(accountUUID: string = '') {
    return this.http.get<any>(`soundblock/reports/account/${accountUUID}`).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  getUserServicesWithProjects() {
    return this.http.get<{
      data: ServiceWithProjects[];
      status: any;
    }>(`soundblock/projects/accounts`);
  }

  // getUserServices() {
  //   return this.http.get<{
  //     data: ServiceWithProjects[] // TODO: Fix the interface to service only
  //   }>(`soundblock/services`);
  // }

  createService(name: string, type: string, paymentId: string) {
    const req = { account_name: name, type, payment_id: paymentId };
    return this.http.post<any>(`soundblock/account/plan/create`, req).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  updateService(uuid, type: string, paymentId: string) {
    const req = { type, payment_id: paymentId };
    return this.http.patch<any>(`soundblock/account/plan/update/${uuid}`, req).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  updateServiceName(name: string, accountUUID: string) {
    return this.http.patch(`soundblock/account/${accountUUID}`, { name });
  }

  cancelService(uuid) {
    const req = {};
    return this.http.post<any>(`soundblock/account/plan/cancel/${uuid}`, req).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  getBasicUserInfo() {
    return this.user.asObservable();
  }

  getBasicUserServicesInfo() {
    return this.services.asObservable();
  }

  uploadAvatar(file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
      .post<{
        data: {
          avatar_url: string;
        };
      }>(`user/avatar`, formData)
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  editName(name) {
    const req = { name };
    return this.http.patch<any>(`user/profile/name`, req).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  getAddress(user?: string, perPage?: number) {
    let params = new HttpParams();
    if (user) {
      params = params.append('user', user);
    }
    if (perPage) {
      params = params.append('per_page', perPage.toString());
    }
    return this.http
      .get<AddressInfoResponse>(`user/profile/address`, { params })
      .pipe(
        map((res) => {
          return res.data.data;
        })
      );
  }

  addAddress(addressInfo: {
    type: string;
    street: string;
    city: string;
    zipCode: string;
    state: string;
    street2: string;
  }) {
    const req = {
      postal_type: addressInfo.type,
      postal_street: addressInfo.street,
      postal_city: addressInfo.city,
      postal_zipcode: addressInfo.zipCode,
      postal_state: addressInfo.state,
      postal_street_additional: addressInfo.street2,
    };

    return this.http.post<any>(`user/profile/address`, req).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  editAddress(
    uuid: string,
    addressInfo: {
      type?: string;
      street?: string;
      city?: string;
      zipCode?: string;
      state?: string;
      flag_primary?: number;
      street2?: string;
    }
  ) {
    const req = {
      postal: uuid,
      postal_type: addressInfo.type,
      postal_street: addressInfo.street,
      postal_city: addressInfo.city,
      postal_zipcode: addressInfo.zipCode,
      postal_state: addressInfo.state,
      flag_primary: addressInfo.flag_primary,
      postal_street_additional: addressInfo.street2,
    };

    return this.http.patch<any>(`user/profile/address`, req).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  deleteAddress(uuid) {
    return this.http.delete<any>(`user/profile/address?postal=${uuid}`).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  getProfileEmailInfo(user?: string, perPage?: number) {
    let params = new HttpParams();
    if (user) {
      params = params.append('user', user);
    }
    if (perPage) {
      params = params.append('per_page', perPage.toString());
    }
    return this.http
      .get<EmailInfoResponse>(`user/profile/email`, { params })
      .pipe(
        map((res) => {
          return res.data.data;
        })
      );
  }

  addEmail(email) {
    const req = { user_auth_email: email };
    return this.http.post<any>(`user/profile/email`, req).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  setPrimaryEmail(oldEmail: string, email: string) {
    const req = {
      old_user_auth_email: oldEmail,
      user_auth_email: email,
      flag_primary: true,
    };
    return this.http
      .patch<{
        data: EmailInfo;
      }>(`user/profile/email`, req)
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  deleteEmail(email) {
    return this.http.delete<any>(`user/profile/email?user_auth_email=${email}`).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  verifyEmail(emailUuid) {
    return this.http.post<any>(`email/${emailUuid}/verify`, {}).pipe(
      map((res) => {
        return res;
      })
    );
  }

  confirmEmailHash(hash: string) {
    return this.http.patch<any>(`core/email/${hash}`, {});
  }

  getProfilePhoneInfo(user?: string, perPage?: number) {
    let params = new HttpParams();

    if (user) {
      params = params.append('user', user);
    }

    if (perPage) {
      params = params.append('per_page', perPage.toString());
    }

    return this.http
      .get<PhoneInfoResponse>(`user/profile/phone`, { params })
      .pipe(
        map((res) => {
          return res.data.data;
        })
      );
  }

  addPhone(type: string, phoneNumber: string, flag) {
    const req = { phone_type: type, phone_number: phoneNumber, flag_primary: flag };
    return this.http.post<any>(`user/profile/phone`, req).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  setPrimaryPhone(phone: string) {
    const req = { old_phone_number: phone, flag_primary: true };
    return this.http
      .patch<{
        data: PhoneInfo;
      }>(`user/profile/phone`, req)
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  deletePhone(phoneNumber) {
    return this.http.delete<any>(`user/profile/phone?phone_number=${phoneNumber}`).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  deleteArtist(deleteArtistRequest: DeleteArtistRequest) {
    return this.http.delete<any>(`soundblock/account/database/artists`, {
      params: deleteArtistRequest as any,
    });
  }

  deleteArtistPublisher(deleteArtistPublisherRequest: DeleteArtistPublisherRequest) {
    return this.http.delete<any>(`soundblock/account/database/artists/publisher`, {
      params: deleteArtistPublisherRequest as any,
    });
  }

  deleteContributor(deleteContributorRequest: DeleteContributorRequest) {
    return this.http.delete<any>(`soundblock/account/database/contributors`, {
      params: deleteContributorRequest as any,
    });
  }

  addArtist(addArtistRequest: AddArtistRequest) {
    const formData = new FormData();
    if (addArtistRequest.avatar) {
      formData.append('avatar', addArtistRequest?.avatar);
    }
    if (addArtistRequest?.account) {
      formData.append('account', addArtistRequest?.account);
    }
    if (addArtistRequest?.artist_name) {
      formData.append('artist_name', addArtistRequest?.artist_name);
    }
    if (addArtistRequest?.url_spotify) {
      formData.append('url_spotify', addArtistRequest?.url_spotify);
    }
    if (addArtistRequest?.url_apple) {
      formData.append('url_apple', addArtistRequest?.url_apple);
    }
    if (addArtistRequest?.url_soundcloud) {
      formData.append('url_soundcloud', addArtistRequest?.url_soundcloud);
    }
    if (addArtistRequest?.project_uuid) {
      formData.append('project_uuid', addArtistRequest?.project_uuid);
    }
    return this.http.post<any>(`soundblock/account/database/artists`, formData);
  }

  addContributor(addContributorRequest: AddContributorRequest) {
    const formData = new FormData();
    if (addContributorRequest?.account) {
      formData.append('account', addContributorRequest?.account);
    }

    if (addContributorRequest?.contributor_name) {
      formData.append('contributor_name', addContributorRequest?.contributor_name);
    }

    return this.http.post<any>(`soundblock/account/database/contributors`, formData);
  }

  addArtistPublisher(addArtistPublisherRequest: AddArtistPublisherRequest) {
    return this.http.post<any>(`soundblock/account/database/artists/publisher`, {
      ...addArtistPublisherRequest,
    });
  }

  editArtist(editArtistRequest: EditArtistRequest) {
    const formData = new FormData();
    if (typeof editArtistRequest?.avatar === 'object') {
      formData.append('avatar', editArtistRequest?.avatar);
    }
    if (editArtistRequest?.account) {
      formData.append('account', editArtistRequest?.account);
    }
    if (editArtistRequest?.artist_name) {
      formData.append('artist_name', editArtistRequest?.artist_name);
    }
    if (editArtistRequest?.artist) {
      formData.append('artist', editArtistRequest?.artist);
    }
    formData.append('url_spotify', editArtistRequest?.url_spotify || '');
    formData.append('url_apple', editArtistRequest?.url_apple || '');
    formData.append('url_soundcloud', editArtistRequest?.url_soundcloud || '');
    formData.append('_method', 'patch');

    return this.http.post<any>(`soundblock/account/database/artists`, formData);
  }

  editContributor(contributor: EditContributorRequest) {
    const formData = new FormData();
    formData.append('account', contributor.account);
    formData.append('contributor', contributor.contributor);
    formData.append('contributor_name', contributor.contributor_name);
    formData.append('_method', 'patch');

    return this.http.post<any>(`soundblock/account/database/contributors`, formData);
  }

  editArtistPublisher(editArtistPublisherRequest: EditArtistPublisher) {
    return this.http.patch<any>(`soundblock/account/database/artists/publisher`, {
      ...editArtistPublisherRequest,
    });
  }

  setPrimaryPayment(payment) {
    let req;
    if (payment.bank_uuid) {
      req = { type: 'bank', flag_primary: true, bank: payment.bank_uuid };
    } else {
      req = { type: 'paymethod', flag_primary: true, paymethod: payment.pay_method_uuid };
    }
    return this.http.patch<any>(`user/profile/payment/primary`, req).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  getPaypal(user?, perPage?) {
    let params = new HttpParams();
    if (user) {
      params = params.append('user', user);
    }
    if (perPage) {
      params = params.append('per_page', perPage);
    }
    return this.http
      .get<{
        data: PaymethodInfo[];
        status: any;
      }>(`user/profile/paymethod`, { params })
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  addPaypal(account: string, type: string) {
    const req = {
      paymethod_account: account,
      paymethod_type: type,
    };
    return this.http.post<any>(`user/profile/paymethod`, req).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  deletePaypal(uuid) {
    return this.http.delete<any>(`user/profile/paymethod?paymethod=${uuid}`).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  getBank(user?, perPage?) {
    let params = new HttpParams();
    if (user) {
      params = params.append('user', user);
    }
    if (perPage) {
      params = params.append('per_page', perPage);
    }
    return this.http
      .get<{
        data: BankingInfo[];
        status: any;
      }>(`user/profile/bank`, { params })
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  addBankAccount(bank) {
    const req = {
      bank_name: bank.name,
      account_type: bank.accountType,
      account_number: bank.accountNumber,
      routing_number: bank.routingNumber,
    };
    return this.http
      .post<{
        data: BankingInfo;
        status: any;
      }>(`user/profile/bank`, req)
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  deleteBankAccount(uuid) {
    return this.http.delete<any>(`user/profile/bank?bank=${uuid}`).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  getAnnoucements(curPage: number, perPage: number) {
    let params = new HttpParams();
    params = params.append('page', curPage.toString());
    params = params.append('per_page', perPage.toString());

    return this.http
      .get<any>(`soundblock/announcements`, { params })
      .pipe(map((res) => res.data));
  }

  getAnnoucement(uuid: string, curPage: number = 1, perPage: number = 1) {
    let params = new HttpParams();
    params = params.append('page', curPage.toString());
    params = params.append('per_page', perPage.toString());

    return this.http
      .get<any>(`soundblock/announcements/${uuid}`, { params })
      .pipe(map((res) => res.data));
  }

  isFreeAccount(accountUuid) {
    const account = this.services.value.find(
      (service) => service?.account?.account_uuid === accountUuid
    );
    return account?.account?.plan_name === 'Simple Distribution';
  }

  isFraudAccount(accountUuid): boolean {
    const account = this.services.value.find(
      (service) => service?.account?.account_uuid === accountUuid
    );

    return !!account?.account?.flag_fraud;
  }

  /**
   * Get current balance
   * @returns Observable<any> return the result of this endpoint.
   */
  getCurrentBalance() {
    return this.http
      .get<any>(`soundblock/payment/balance/current`)
      .pipe(tap((res) => this.userBalance.next(res.data.user_balance || 0)));
  }

  /**
   * Get balance history
   * @param historyType required, should be withdrawal/earned
   * @param page optional
   * @param perPage optional
   * @returns Observable<any> return the result of this endpoint.
   */
  getBalanceHistory(historyType: string, page: number = 1, perPage: string = '10') {
    const params = {
      history_type: historyType,
      per_page: perPage,
      page: page.toString(),
    };

    return this.http
      .get<any>(`soundblock/payment/balance/history`, { params })
      .pipe(
        map((res) => {
          return {
            data: res.data.data.map((operation) => {
              return {
                date: new Date(operation.stamp_created * 1000),
                startDate: operation.date_starts
                  ? moment(operation.date_starts, 'YYYY-MM-DD').format('MM/DD')
                  : null,
                endDate: operation.date_ends
                  ? moment(operation.date_ends, 'YYYY-MM-DD').format('MM/DD')
                  : null,
                reportPeriod: operation.date_ends
                  ? moment(operation.date_ends, 'YYYY-MM-DD').format('MMMM, YYYY')
                  : null,
                balance: operation.user_balance,
                memo: operation.payment_memo,
                payment_memo_details: operation.payment_memo_details,
                status: operation.withdrawal_status
                  ? operation.withdrawal_status
                  : 'Deposit',
                amount: operation.payment_amount,
                project: operation.project_uuid,
                platform: operation.platform_uuid,
              };
            }),
            lastPage: res.data.last_page,
          };
        })
      );
  }

  /**
   * Create withdrawal operation
   * @param withdrawMethod required, should be banking/paypal
   * @param withdrawalUUID required,
   * @param withdrawalAmount required, min 10$
   * @returns Observable<any> return the result of this endpoint.
   */
  makeWithdraw(withdrawMethod, withdrawalUUID, withdrawalAmount) {
    const params = {
      withdrawal_method: withdrawMethod,
      withdrawal_uuid: withdrawalUUID,
      withdrawal_amount: withdrawalAmount,
    };

    return this.http
      .post<any>(`soundblock/payment/withdrawal`, { ...params })
      .pipe(map((res) => res.data));
  }

  updateW9FormStatus() {
    const body = {};
    return this.http.patch<any>(`soundblock/w9/notapplicable`, body);
  }

  getServiceAccountUsers(accountUUID: string): Observable<TeamData> {
    return this.http.get<any>(`soundblock/account/${accountUUID}/teams/users`).pipe(
      map((res) => {
        this.allProjects.next(res.data.projects);
        return res;
      })
    );
  }
}
