import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HeaderPage } from './header.page';
import { CommonComponentsModule } from 'src/app/components/common.module';
import { PopoverModule, PopoverConfig } from 'ngx-bootstrap/popover';

const routes: Routes = [
  {
    path: '',
    component: HeaderPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CommonComponentsModule,
    RouterModule.forChild(routes),
    PopoverModule,
  ],
  declarations: [HeaderPage],
  exports: [HeaderPage],
  providers: [PopoverConfig],
})
export class HeaderPageModule {}
