<div (click)="showTicketbar()" class="tickets-backdrop"></div>
<div class="ticketWrapper">
  <div class="container">
    <div class="row ticketHeader">
      <span class="w-auto">Support Desk</span>
      <i
        class="fas fa-long-arrow-left w-auto"
        tooltip="Back to Tickets"
        container="body"
        (click)="backToTicket()"
        *ngIf="pageStatus"
      ></i>
      <i class="fas fa-close w-auto" (click)="showTicketbar()" *ngIf="!pageStatus"></i>
    </div>
    <div class="row ticketList" *ngIf="pageStatus == 0">
      <div class="listHeader mb-2">
        <button
          class="btn btn-outline-gradient new-ticket"
          shape="semi-round"
          fullWidth
          (click)="showTicketPage()"
        >
          <div class="wrapper">
            <span class="gradientText"> New Ticket </span>
          </div>
        </button>
      </div>
      <!-- TICKETS LIST START -->
      <div class="infinite_content" #ticketsIonContent>
        <h6 *ngIf="!tickets.length && ticketsLoaded">
          <p><i>We're here to help!</i></p>
          <p><i>Please start a new ticket if you need assistance.</i></p>
        </h6>
        <div class="listWrapper" *ngIf="tickets.length">
          <div
            class="infinite_scroll"
            infinite-scroll
            [infiniteScrollDistance]="scrollDistance"
            [scrollWindow]="false"
            (scrolled)="loadNextTicketsPage($event)"
          >
            <div *ngFor="let ticket of tickets; let index = index" class="ticket">
              <div class="p-3 card m-2" (click)="openTicket(ticket)">
                <div class="header row">
                  <div>
                    <i class="fas fa-envelope"></i>
                    <span>{{ ticket.support.support_category }}</span>
                  </div>
                  <div
                    class="alert"
                    *ngIf="
                      ticket.flag_status == 'Awaiting Customer' ||
                      ticket.flag_status == 'Awaiting Support'
                    "
                    [ngClass]="{
                      'pink-gradient':
                        ticket.flag_status == 'Awaiting Customer' ||
                        ticket.flag_status == 'Awaiting Support'
                    }"
                    status="success"
                  >
                    {{ ticket.flag_status }}
                  </div>
                  <div
                    class="alert"
                    *ngIf="ticket.flag_status == 'Open'"
                    [ngClass]="{ purple: ticket.flag_status == 'Open' }"
                    status="info"
                  >
                    {{ ticket.flag_status }}
                  </div>
                  <div
                    class="alert"
                    *ngIf="ticket.flag_status == 'Closed'"
                    [ngClass]="{ blue: ticket.flag_status == 'Closed' }"
                    status="warning"
                  >
                    {{ ticket.flag_status }}
                  </div>
                </div>
                <div class="body row">
                  <div class="col-12">
                    <span>{{ ticket.ticket_title }}</span>
                  </div>
                  <div class="contact col-6">
                    <span>
                      Created: {{ ticket.stamp_created | timestamp: 'MMMM d, y' }}
                    </span>
                    <span class="d-block ticket-user">{{
                      ticket.stamp_created_by.name | uppercase
                    }}</span>
                  </div>
                  <div class="contact col-6">
                    <span>
                      Updated: {{ ticket.stamp_updated | timestamp: 'MMMM d, y' }}
                    </span>
                    <span class="d-block ticket-user">{{
                      ticket.stamp_updated_by.name | uppercase
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="loading_tickets" *ngIf="!ticketsLoaded">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <span class="mt-3">Loading tickets...</span>
            </div>
          </div>
        </div>
      </div>
      <!-- TICKETS LIST END -->
    </div>
    <div class="msgList" *ngIf="pageStatus == 1">
      <div class="title">
        <span>{{ ticket.ticket_title }}</span>
      </div>
      <div class="metainfo">
        <span>Type: {{ ticket.support.support_category }}</span>
        <span>Created At: {{ ticket.stamp_created | timestamp: 'MMMM d, y' }}</span>
        <!-- <span>ID: {{ticket.uuid}}</span> -->
      </div>
      <div class="body">
        <div class="content" #ticketMessagesIonContent style="height: calc(100% - 111px)">
          <div class="messages" style="height: 100%">
            <div *ngIf="ticketMessages.length" style="height: 100%">
              <div
                #scroll
                class="message_infinite_scroll"
                infinite-scroll
                [infiniteScrollDistance]="scrollDistance"
                [scrollWindow]="false"
                (scrolled)="loadNextTicketMessagesPage($event)"
              >
                <div
                  *ngFor="let message of ticketMessages; let index = index"
                  class="message"
                >
                  <div class="wrapper card">
                    <div class="messageHeader">
                      <img [src]="message?.user?.avatar_url" />
                      <div class="name">
                        <span class="name">
                          <span>{{ message.user?.name_first }} </span>
                          <span>{{ message.user?.name_last }}</span>
                        </span>
                        <span class="date">{{
                          message?.stamp_created | timestamp: 'MMMM d, y'
                        }}</span>
                      </div>
                    </div>
                    <div class="messageBody row">
                      <div>
                        <span [innerHTML]="replaceText(message.message_text)"></span>
                      </div>
                      <div
                        class="fileCard"
                        *ngFor="let attachment of message.attachments"
                        tooltip="Download"
                        (click)="download(attachment)"
                      >
                        <div class="fileImg">
                          <i class="fas fa-download"></i>
                        </div>
                        <div class="fileInfo">
                          <span>{{ attachment.attachment_name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="loading_tickets" *ngIf="!msgLoaded">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span class="mt-3">Loading messages...</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="messageInput mb-2" *ngIf="ticket.flag_status !== 'Closed'">
          <div class="input">
            <textarea
              placeholder="Please enter a message"
              autoGrow="true"
              style="white-space: no-wrap"
              [disabled]="ticket.flag_status === 'Closed'"
              (keydown.enter)="$event.preventDefault(); sendMessage()"
              (input)="setMessage($event)"
              [(ngModel)]="message"
              [ngClass]="{
                'is-invalid': invalidMessage,
                disabled: ticket.flag_status === 'Closed'
              }"
            ></textarea>
          </div>
          <div class="control">
            <div class="attachSection">
              <input
                #attachFileRef
                type="file"
                name="attachFile"
                id="attachFile"
                class="inputfile"
                (change)="attachFile($event.target.files)"
                [disabled]="ticket.flag_status === 'Closed'"
                [ngClass]="{ disabled: ticket.flag_status === 'Closed' }"
              />
              <div
                class="icon"
                [tooltip]="ticket.flag_status !== 'Closed' && 'Attach File'"
                [ngClass]="{ disabled: ticket.flag_status === 'Closed' }"
                (click)="attachFileRef.click()"
              >
                <i
                  class="fas fa-paperclip"
                  [ngClass]="{ disabled: ticket.flag_status === 'Closed' }"
                ></i>
              </div>
              <div class="fileInfo" *ngIf="file">
                <span>Attached File: </span>
                <span class="filename"> {{ file.name }} </span>
                <span class="filesize"> {{ file.size | fileSize }}</span>
              </div>
            </div>
            <button
              shape="semi-round"
              fullWidth
              class="btn btn-outline-gradient"
              (click)="sendMessage()"
              [disabled]="ticket.flag_status === 'Closed'"
              [ngClass]="{ disabled: ticket.flag_status === 'Closed' }"
            >
              <span class="gradientText">Send</span>
            </button>
          </div>
          <div *ngIf="invalidFileExtension" class="alert alert-danger mt-1">
            {{ error }}
          </div>
        </div>
        <div class="closedInfo mb-2" *ngIf="ticket.flag_status === 'Closed'">
          <p>This ticket is closed.</p>
          <p>
            Please
            <span class="custom-link" (click)="showTicketPage()">click here</span> to
            start a new one.
          </p>
        </div>
      </div>
    </div>
    <div class="newTicket" *ngIf="pageStatus == 2">
      <form #form="ngForm">
        <div class="p-2 row">
          <div class="col-6">
            <div class="p-1">Type:</div>
            <ng-select
              name="type"
              class="type"
              required
              [(ngModel)]="support_type"
              placeholder="Type"
              #type="ngModel"
              [ngClass]="{
                'is-invalid': (type.touched || form.submitted) && !type.valid
              }"
              appendTo="body"
            >
              <ng-option *ngFor="let type of ticketTypes" [value]="type">
                {{ type }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-12">
            <div class="p-1">Subject:</div>
            <div>
              <input
                type="text"
                name="subject"
                [(ngModel)]="ticket_title"
                required
                class="form-input w-100"
                placeholder="Subject"
                #subject="ngModel"
                [ngClass]="{
                  'is-invalid': (subject.touched || form.submitted) && !subject.valid
                }"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="p-1">Message:</div>
            <div class="messageWrapper">
              <textarea
                name="message"
                required
                class="form-textarea"
                placeholder="Please input message"
                [(ngModel)]="message"
                #ticketMessage="ngModel"
                [ngClass]="{
                  'is-invalid':
                    (ticketMessage.touched || form.submitted) && !ticketMessage.valid
                }"
              ></textarea>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="fileWrapper">
              <input
                type="file"
                name="attachFile"
                id="attachFile"
                class="inputfile"
                (change)="attachFile($event.target.files)"
              />
              <div class="attach" onclick="document.getElementById('attachFile').click()">
                <i class="fas fa-paperclip"></i><span>Attach File</span>
              </div>
              <div class="fileInfo" *ngIf="file">
                <span>{{ file.name }}</span>
                <span class="filesize">{{ file.size | fileSize }}</span>
              </div>
            </div>
            <div *ngIf="invalidFileExtension" class="alert alert-danger mt-1">
              {{ error }}
            </div>
          </div>
        </div>
        <div class="horizontalCenterRow pb-3 row">
          <button
            shape="semi-round"
            status="primary"
            (click)="createTicket(form)"
            class="btn btn-gradient"
            [ngClass]="{ 'col-8': ticketCreating }"
          >
            Create Ticket
          </button>
          <span *ngIf="ticketCreating" class="m-auto col-2">Sending...</span>
          <span
            [csLoading]="ticketCreating"
            [ngClass]="{ 'col-2': ticketCreating }"
          ></span>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #loading>
  <button class="btn loading_button" type="button" disabled *ngIf="totalTickets === 0">
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
      style="width: 1.5rem; height: 1.5rem"
    ></span>
    <span class="ms-2">Loading...</span>
  </button>
</ng-template>
