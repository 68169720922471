import { Directive, OnInit, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appIsRendered]',
})
export class RenderDirective implements OnInit {
  @Output() isAlreadyRendered = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.isAlreadyRendered.emit();
  }
}
