import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './ui';

/* Layout components */
import { PageFooterPageModule } from './layout/footer/page-footer.module';
import { HeaderPageModule } from './layout/header/header.module';

/* Common component modules */
import { CommonComponentsModule } from './components/common.module';
import { ProjectComponentsModule } from './components/project.module';

/* Services */
import { StartupService } from './services/shared/startup';

/* Directives */
import { PipeModule } from './core/pipes/pipe.module';

/* Guards */

/* Interceptors */
import { JwtInterceptor } from './core/interceptors/jwtInterceptor';

/* Factory */
import { TitleCasePipe } from '@angular/common';
import { startupServiceFactory } from './core/factories/startup';
import { SignUpGuard } from './core/guard/sign-up';
import { AdminFooterModule } from './layout/admin-footer/admin-footer.module';

@NgModule({
  declarations: [
    // Components
    AppComponent,
  ],
  imports: [
    // Core modules
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    HeaderPageModule,
    AdminFooterModule,
    PageFooterPageModule,
    ProjectComponentsModule,
    CommonComponentsModule,
    PipeModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    SignUpGuard,
    CookieService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({}),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      multi: true,
      deps: [StartupService, Sentry.TraceService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    TitleCasePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
