import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth';
import { ReverseAuthGuard } from './core/guard/reverse-auth';
import { SignUpGuard } from './core/guard/sign-up';
import { TicketSidebarComponent } from './components/ticketbar/ticketbar.component';
import { DatabaseTableComponent } from './components/project/table/database-table/database-table.component';
import { ProjectsListComponent } from './pages/projects/projects-list/projects-list.component';
import { AnnouncementsComponent } from './pages/projects/announcements/announcements.component';
import { ProjectsPage } from './pages/projects/projects.page';
import { DraftsComponent } from './pages/projects/drafts/drafts.component';
import { ProjectInvitesComponent } from './components/project/layout/project-invites/project-invites.component';

const routes: Routes = [
  {
    path: 'home',
    redirectTo: 'projects',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'email/:hash',
    loadChildren: () =>
      import('./pages/auth/email-confirm/email-confirm.module').then(
        (m) => m.EmailConfirmPageModule
      ),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./pages/reports/reports.module').then((m) => m.ReportsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'support',
    component: TicketSidebarComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project',
    loadChildren: () =>
      import('./pages/projects/project/project.module').then((m) => m.ProjectPageModule),
  },
  {
    path: 'invite/:hash',
    loadChildren: () =>
      import('./pages/auth/invite/invite.module').then((m) => m.InvitePageModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'test',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/projects/projects.module').then((m) => m.ProjectsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
