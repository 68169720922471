<div class="mobileMenu">
  <div class="row mx-0 header">
    <img
      routerLinkActive="router-link-active"
      src="{{ cloudUrl }}/images/soundblock-logo.png"
      class="w-auto"
    />
    <span class="beta-mobile-menu w-auto">beta</span>
    <i class="fas fa-close w-auto" (click)="close()"></i>
  </div>
  <div class="row mx-0 content">
    <div class="col-6 p-1 leftPane">
      <div
        class="customCard transition-action"
        (click)="navigate('account')"
        *ngIf="authService.isAuthorized"
      >
        <i class="icon-colors-2 fad fa-user-cog"></i><span>Account</span>
      </div>
      <div
        [hidden]="!userHasService"
        class="customCard transition-action"
        (click)="navigate('projects')"
        *ngIf="authService.isAuthorized"
      >
        <i class="icon-colors-2 fad fa-user-music"></i><span>Projects</span>
      </div>
      <div
        class="customCard transition-action"
        (click)="navigate('reports')"
        *ngIf="authService.isAuthorized && !hasFraudAccount"
      >
        <i class="icon-colors fad fa-user-chart"></i><span>Reports</span>
      </div>
      <div
        class="customCard transition-action"
        (click)="navigate('auth/signin')"
        *ngIf="!authService.isAuthorized"
      >
        <i class="icon-colors fad fa-sign-in-alt"></i><span>Sign In</span>
      </div>
      <div
        class="customCard transition-action"
        (click)="navigate('auth/signup')"
        *ngIf="!authService.isAuthorized"
      >
        <i class="icon-colors fad fa-user-plus"></i><span>Sign Up</span>
      </div>
    </div>
    <div class="col-6 rightPane p-1">
      <div class="row mx-0 avatar">
        <img class="mb-2 w-auto" [src]="user.avatar" />
      </div>
      <div class="row mx-0 horizontalCenterRow">
        <b class="w-auto">{{ user.name }}</b>
      </div>
      <div class="row mx-0 links">
        <button class="btn btn-gradient" (click)="navigate('profile')">Profile</button>
        <!--        <button nbButton status="primary" (click)="showNotification()">-->
        <!--          Notifications-->
        <!--        </button>-->
        <button class="btn btn-gradient" (click)="showTicketbar()">Support</button>
      </div>
      <div class="row mx-0 signOut">
        <button
          class="transition-action btn btn-gradient"
          (click)="signOut()"
          *ngIf="authService.isAuthorized"
        >
          <i class="icon-colors fad fa-sign-out-alt me-1"></i
          ><span style="margin-top: 4px">Sign Out</span>
        </button>
      </div>
    </div>
  </div>
  <!--  <div class="notify">-->
  <!--    <div class="title">-->
  <!--      <span>Notifications</span>-->
  <!--    </div>-->
  <!--    <div class="itemWrapper">-->
  <!--      &lt;!&ndash; <ion-slides pager="true" [options]="slideOpts" pager="true">-->
  <!--        <ion-slide *ngFor="let notifyItem of notifications; let index = index">-->
  <!--          <div class="notifyItem">-->
  <!--            <ion-row class="title">-->
  <!--              <span class="gradientText"-->
  <!--                ><b>{{ notifyItem.notification_name }}</b></span-->
  <!--              >-->
  <!--            </ion-row>-->
  <!--            <ion-row class="body">-->
  <!--              <ion-col size="2" class="bellIcon">-->
  <!--                <i class="fad fa-bells icon-colors"></i>-->
  <!--              </ion-col>-->
  <!--              <ion-col size="10" class="contentWrapper">-->
  <!--                <p>{{ notifyItem.notification_memo }}</p>-->
  <!--              </ion-col>-->
  <!--            </ion-row>-->
  <!--            <div-->
  <!--              class="actions"-->
  <!--              *ngIf="notifyItem.notification_action && notifyItem.notification_action === 'Default Action' && notifyItem.notification_action === 'Not Triggered'"-->
  <!--              [innerHTML]="notifyItem.notification_action | safeHtml"-->
  <!--            ></div>-->
  <!--          </div>-->
  <!--        </ion-slide>-->
  <!--      </ion-slides> &ndash;&gt;-->
  <!--    </div>-->
  <!--  </div>-->
</div>
