<div
  class="card notificationToast"
  *ngFor="let toast of toasts"
  @newTrigger
  (click)="removeToast(toast)"
>
  <div class="card-header">
    <span>Soundblock</span>
  </div>
  <div class="card-body">
    <div class="row mx-0 content">
      <div class="bellIcon">
        <i class="fad fa-bells icon-colors"></i>
      </div>
      <div class="message">
        <p>
          <b>{{ toast.notification.notification_memo }}</b>
        </p>
      </div>
    </div>
    <div
      class="row mx-0 actions"
      [innerHtml]="toast.notification.notification_action | safeHtml"
      *ngIf="toast.notification.notification_action"
    ></div>
  </div>
</div>
