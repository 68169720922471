import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileService } from 'src/app/services/account/profile';

@Component({
  selector: 'app-admin-footer',
  templateUrl: './admin-footer.component.html',
  styleUrls: ['./admin-footer.component.scss'],
})
export class AdminFooter implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  user: any;

  windowWidth = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;
    this.resizeContent();
  }

  constructor(
    private profileService: ProfileService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.profileService
      .getBasicUserInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.user = res;
      });
  }

  get AdminCookie() {
    return this.cookieService.get('admin_cookie') && this.user;
  }

  resizeContent() {
    if (!this.AdminCookie) return;

    if (this.windowWidth >= 768)
      document.documentElement.style.setProperty(
        '--maxHeightContent',
        'calc(100vh - 144px)'
      );
    if (this.windowWidth < 768)
      document.documentElement.style.setProperty(
        '--maxHeightContent',
        'calc(100vh - 120px)'
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
