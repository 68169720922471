<div class="notificationModal">
  <div class="modal-header" modalDraggable>
    <span class="me-auto">Notifications</span>
    <button (click)="close()" type="button">
      <span aria-hidden="true"><i class="fal fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mx-0 notificationTab">
      <div class="header">
        <div class="wrapper">
          <div class="left">
            <div class="check">
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="checkAll"
                  [(ngModel)]="checkedAll"
                  (change)="onCheckAll()"
                />
                <label class="form-check-label" for="checkAll"></label>
              </div>
              <span class="ms-2">Select All</span>
            </div>
            <div class="iconBtn" (click)="getNotifications(paginationInfo?.current_page)">
              <i class="fas fa-sync"></i>
            </div>
            <div class="iconBtn" (click)="onBatchArchive()" *ngIf="checkedArr.length > 0">
              <i class="fas fa-archive"></i>
            </div>
            <div class="iconBtn" (click)="onBatchDelete()" *ngIf="checkedArr.length > 0">
              <i class="fas fa-trash"></i>
            </div>
          </div>
          <div *ngIf="paginationInfo?.total_pages > 1" class="right">
            <span class="me-2">
              {{ paginationInfo?.per_page * (paginationInfo?.current_page - 1) + 1 }}-{{
                paginationInfo?.per_page * (paginationInfo?.current_page - 1) +
                  paginationInfo?.count
              }}
              of {{ paginationInfo?.total }}
            </span>
            <button
              class="iconBtn"
              [disabled]="!paginationInfo?.links?.previous"
              (click)="getNotifications(paginationInfo?.current_page - 1)"
            >
              <i class="fas fa-angle-left"></i>
            </button>
            <button
              class="iconBtn"
              [disabled]="!paginationInfo?.links?.next"
              (click)="getNotifications(paginationInfo?.current_page + 1)"
            >
              <i class="fas fa-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="notificationsObs | async; else loading" class="full-width notiList">
        <div class="notification" *ngFor="let item of notifications; let index = index">
          <div class="check">
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="check{{ index }}"
                [(ngModel)]="checkedFlag[index]"
                (change)="checkNotification($event, item, index)"
              />
              <label class="form-check-label" for="check{{ index }}"></label>
            </div>
          </div>
          <div class="avatar">
            <!-- <img [src]="item.avatar"/> -->
            <img src="assets/images/bj.png" />
          </div>
          <div
            class="content"
            [ngClass]="{
              unread: item.notification_detail.notification_state == 'unread'
            }"
            (click)="expandItem(index)"
          >
            <div class="top">
              <span
                ><b>{{ item.notification_name }}</b></span
              >
              <div class="action">
                <i
                  class="fas fa-envelope"
                  tooltip="Mark as Read"
                  (click)="onRead($event, item, index)"
                ></i>
                <i
                  class="fas fa-archive"
                  tooltip="Archive"
                  (click)="onArchive($event, item)"
                ></i>
                <i
                  class="fas fa-trash"
                  tooltip="Delete"
                  (click)="onDelete($event, item)"
                ></i>
              </div>
            </div>
            <div class="bottom expand" [ngClass]="{ expand: expandFlag[index] }">
              <span>{{ item.notification_memo }}</span>
              <div
                class="action"
                *ngIf="
                  item.notification_action &&
                  item.notification_action === 'Default Action' &&
                  item.notification_action === 'Not Triggered'
                "
                [innerHTML]="item.notification_action | safeHtml"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <button class="btn loading_button" type="button" disabled>
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
      style="width: 1.5rem; height: 1.5rem"
    ></span>
    <span class="ms-2">Loading...</span>
  </button>
</ng-template>
