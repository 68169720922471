export const environment =
      {
        apiUrl:     "https://core.api.arena.com/",
        cloudUrl:   "https://cloud.soundblock.arena.com/deployments/6ce1b95e7d8e7cd3b6a5531051521c5b65e65404/assets",
        pusherApiKey: "82ac4cd7763cb37f7226",
        pusherCluster: "us2",
        production: "true",
        gitSha:     "6ce1b95e7d8e7cd3b6a5531051521c5b65e65404",
        envName: "production",
        stripeAPIKey: "pk_live_51I1dvrC5iJevKSWRcTbYqLAlI2xivWhj3IOWtvedJxFEhYNWhuIBce4ZcDkS9k4hbZhNggVJ1k0s5PkLKyiZfkBp008xj3l18m"
      };
    