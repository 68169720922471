<div class="app">
  <app-header></app-header>
  <div class="main-content">
    <div class="alert-wrapper" *ngIf="hasFraudAccount">
      <div class="alert alert-danger text-white text-center">
        Due to suspected fraudulent activity on your account, all services have been
        suspended. If you believe this to be in error, please contact our support desk.
      </div>
    </div>
    <router-outlet id="rootContent"></router-outlet>
  </div>
  <app-admin-footer></app-admin-footer>
  <app-ticket-sidebar
    *ngIf="ticketbarVisible"
    [technicalSupport]="ticketTechnicalSupport"
  ></app-ticket-sidebar>
  <app-toast class="custom-toast-{{toastPosition}}" id="notification-toast"></app-toast>
</div>
