import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/local';
import { AuthService } from '../account/auth';
import { Subject, BehaviorSubject } from 'rxjs';

interface ArenaUserData {
  p: any;
  r: string;
  a: string;
}
@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  ticketMessagesCount$ = new BehaviorSubject(null);
  user: User;
  constructor(private authService: AuthService) {}

  subscribeToUserChannel(user) {
    this.user = user;
    const pusher = new Pusher(environment.pusherApiKey, {
      authEndpoint: `${environment.apiUrl}broadcasting/auth`,
      cluster: environment.pusherCluster,
      forceTLS: true,
      encrypted: true,
      auth: {
        params: {},
        headers: {
          Authorization: `Bearer ${this.authService.accessToken}`,
        },
      },
    });
    const channel = pusher.subscribe('private-Arena.User.' + this.user.user_uuid);
    channel.bind('Arena.User.' + this.user.user_uuid, (data: ArenaUserData) => {
      if (data.a === 'Arena') {
        switch (data.r) {
          case 'tickets/unread':
            this.setTicketMessagesCount(data?.p?.count);
        }
      }
    });
  }

  setTicketMessagesCount(count) {
    this.ticketMessagesCount$.next(count);
  }
}
