import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MenuComponent } from 'src/app/components/common/mobilemenu/mobilemenu.component';
import { NotificationComponent } from 'src/app/components/common/notification/notification.component';

import { SharedService } from 'src/app/services/shared/shared';
import { AuthService } from 'src/app/services/account/auth';
import { ProfileService } from 'src/app/services/account/profile';
import { PanelService } from 'src/app/services/shared/panel';
import { NotificationService } from 'src/app/services/support/notification';
import { MatDialog } from '@angular/material/dialog';

import { environment } from 'src/environments/local';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ServiceData } from 'src/app/models/service';
import { TicketService } from 'src/app/services/support/ticket';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BroadcastService } from 'src/app/services/user-broadcast/broadcast.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.page.html',
  styleUrls: ['./header.page.scss'],
})
export class HeaderPage implements OnInit, OnDestroy {
  @ViewChild('userMenuPopover') userMenuPopover;
  private destroy$ = new Subject<void>();
  user: any;
  sidebar: any;
  historybarVisible: boolean;
  ticketbarVisible: boolean;
  currentPage = '';
  services: ServiceData[];
  cloudUrl = environment.cloudUrl;
  ticketNotifications: number;
  notificationInterval: any;
  public hasFraudAccount: boolean;

  get userHasService(): boolean {
    return this.profileService.userHasService;
  }

  constructor(
    private router: Router,
    public authService: AuthService,
    public sharedService: SharedService,
    public notificationService: NotificationService,
    private profileService: ProfileService,
    private panelService: PanelService,
    private ticketService: TicketService,
    private modalService: BsModalService,
    private broadcastService: BroadcastService
  ) {}

  ngOnInit() {
    this.watchPanelStatus();
    this.watchRouter();
    this.getBasicUserInfo();
    this.getBasicUserServicesInfo();
  }

  watchTicketNotifications() {
    this.getNotifications();
  }

  getNotifications() {
    this.broadcastService.ticketMessagesCount$.subscribe((res) => {
      this.ticketNotifications = res;
    });
  }

  watchRouter() {
    this.router.events.subscribe((val) => {
      const urlSegs = this.router.url.split('?');
      const pathSegs = urlSegs[0].split('/').filter((value) => value !== '');
      this.currentPage = pathSegs[0];
    });
  }

  watchPanelStatus() {
    this.panelService
      .getTicketbarVisible()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.ticketbarVisible = res;
      });

    this.panelService
      .getHistorybarVisible()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.historybarVisible = res;
      });
  }

  getBasicUserInfo() {
    this.profileService
      .getBasicUserInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.user = res;
        if (this.authService.isAuthorized) {
          this.getNotifications();
        }
      });
  }

  getBasicUserServicesInfo() {
    this.profileService
      .getBasicUserServicesInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.services = res;
        this.hasFraudAccount = res.some((service) => service?.account?.flag_fraud);
      });
  }

  checkReportPermission() {
    if (!this.services) {
      return false;
    }
    for (const service of this.services) {
      for (const permission of service.permissions) {
        if (permission.permission_name === 'App.Soundblock.Account.Report.Payments') {
          return true;
        }
      }
    }
    return false;
  }

  async showUsermenu(ev: any) {
    this.modalService.show(MenuComponent, {
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered',
    });

    // const dialogRef = this.dialog.open(MenuComponent, {
    //   width: '100%',
    //   height: '100%',
    //   maxWidth: '100vw',
    //   maxHeight: '100vh'
    // });
  }

  async showUserPopover(ev: any) {
    // const popover = await this.popoverController.create({
    //   component: UsermenuComponent,
    //   event: ev,
    //   translucent: true,
    //   showBackdrop: false,
    // });
    // return await popover.present();
  }

  showNotification() {
    this.modalService.show(NotificationComponent, {
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered',
    });
  }

  showHideTicketbar() {
    this.panelService.setTicketbarVisible(!this.ticketbarVisible);
  }
  signOut() {
    this.authService.signOut();
  }

  navigatePage(url: string) {
    this.router.navigate([`/${url}`]);
    this.userMenuPopover.hide();
  }

  ngOnDestroy() {
    if (this.notificationInterval) {
      clearInterval(this.notificationInterval);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
