import { NgModule } from '@angular/core';

import { DateAgoPipe } from './date-ago';
import { FileSizePipe } from './size';
import { AccountNumberPipe } from './account-number';
import { DurationPipe } from './duration';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TimestampPipe } from './timestamp.pipe';
import { EpochPipe } from './epoch.pipe';
import { TextTooltipPipe } from './text-tooltip';
import { ProjectLinkPipe } from './project-link.pipe';
import { RoundedCurrencyPipe } from './rounded-currency.pipe';
import { RemoveCountriesPipe } from './remove-countries.pipe';
import { PartBoldPipe } from './part-bold.pipe';

@NgModule({
  declarations: [
    FileSizePipe,
    DateAgoPipe,
    AccountNumberPipe,
    DurationPipe,
    SafeHtmlPipe,
    TimestampPipe,
    EpochPipe,
    TextTooltipPipe,
    ProjectLinkPipe,
    RoundedCurrencyPipe,
    RemoveCountriesPipe,
    PartBoldPipe,
  ],
  exports: [
    FileSizePipe,
    DateAgoPipe,
    AccountNumberPipe,
    DurationPipe,
    SafeHtmlPipe,
    TimestampPipe,
    EpochPipe,
    TextTooltipPipe,
    ProjectLinkPipe,
    RoundedCurrencyPipe,
    RemoveCountriesPipe,
    PartBoldPipe,
  ],
})
export class PipeModule {}
