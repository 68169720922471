import { Component, HostListener, OnDestroy } from '@angular/core';
import * as Sentry from '@sentry/angular';

import { environment } from '../environments/local';

import { TitleCasePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/account/auth';
import { ProfileService } from 'src/app/services/account/profile';
import { PanelService } from 'src/app/services/shared/panel';
import { NotificationService } from 'src/app/services/support/notification';
import { BroadcastService } from './services/user-broadcast/broadcast.service';

@Component({
  selector: 'soundblock',
  templateUrl: 'ui.html',
  styleUrls: ['app.scss'],
})
export class AppComponent implements OnDestroy {
  private destroy$ = new Subject<void>();
  ticketbarVisible = false;
  ticketTechnicalSupport = null;
  public hasFraudAccount: boolean;

  windowWidth = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;
    this.setCssVariables();
  }

  get toastPosition() {
    return this.notificationService.toastPosition;
  }

  constructor(
    private notificationService: NotificationService,
    private profileService: ProfileService,
    private panelService: PanelService,
    private authService: AuthService,
    private broadcastService: BroadcastService,
    private titleCasePipe: TitleCasePipe,
    private titleService: Title
  ) {
    // Set an environment-specific page title for non-production environments
    if (environment.envName !== 'production') {
      this.titleService.setTitle(
        `[${this.titleCasePipe.transform(
          environment.envName
        )}] ${this.titleService.getTitle()}`
      );
    }

    this.initializeApp();
  }

  initializeApp() {
    this.bootload();
    this.profileService
      .getBasicUserServicesInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((services) => {
        this.hasFraudAccount = services.some((service) => service?.account?.flag_fraud);
        this.setCssVariables();
      });
    this.profileService.user.subscribe((user) => {
      if (user) {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: user.user_uuid,
            username: user.name,
            email: user?.primary_email?.user_auth_email,
          });
        });
      }
    });
  }

  setCssVariables() {
    if (this.hasFraudAccount) {
      if (this.windowWidth >= 768)
        document.documentElement.style.setProperty(
          '--maxHeightContent',
          'calc(100vh - 200px)'
        );
      if (this.windowWidth < 768)
        document.documentElement.style.setProperty(
          '--maxHeightContent',
          'calc(100vh - 188px)'
        );
    }
    else {
      if (this.windowWidth >= 768)
        document.documentElement.style.setProperty(
          '--maxHeightContent',
          'calc(100vh - 90px)'
        );
      if (this.windowWidth < 768)
        document.documentElement.style.setProperty(
          '--maxHeightContent',
          'calc(100vh - 78px)'
        );
    }
  }

  bootload() {
    if (this.authService.isAuthorized) {
      this.profileService
        .getBasicUserInfo()
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.notificationService.listen(res);
          this.broadcastService.subscribeToUserChannel(res);
        });
    }
    this.watchTicketbarStatus();
    this.watchTechnicalSupport();
  }

  watchTicketbarStatus() {
    this.panelService
      .getTicketbarVisible()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.ticketbarVisible = res;
      });
  }

  watchTechnicalSupport() {
    this.panelService
      .getTechnicalSupport()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.ticketbarVisible = true;
        this.ticketTechnicalSupport = res;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
