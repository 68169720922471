import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { NotificationComponent } from '../notification/notification.component';
import { NotificationService } from 'src/app/services/support/notification';
import { SharedService } from 'src/app/services/shared/shared';
import { ProfileService } from 'src/app/services/account/profile';
import { AuthService } from 'src/app/services/account/auth';
import { PanelService } from 'src/app/services/shared/panel';

import { environment } from 'src/environments/local';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ServiceData } from 'src/app/models/service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-mobilemenu',
  templateUrl: './mobilemenu.component.html',
  styleUrls: ['./mobilemenu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  user: any;
  avatarPath = `${environment.cloudUrl}/images/user.png`;
  notifications: any;
  public hasFraudAccount: boolean;

  slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  services: ServiceData[];

  cloudUrl = environment.cloudUrl;

  get userHasService(): boolean {
    return this.profileService.userHasService;
  }

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private panelService: PanelService,
    public authService: AuthService,
    private profileService: ProfileService,
    public sharedService: SharedService,
    public notificationService: NotificationService,
    private dialogRef: MatDialogRef<MenuComponent>,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getBasicUserInfo();
    this.getBasicUserServicesInfo();

    this.notificationService
      .getNotifications(1)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.notifications = res.data;
      });
  }

  wifiSetting() {
    // code for setting wifi option in apps
  }

  getBasicUserInfo() {
    this.profileService
      .getBasicUserInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.user = res;
      });
  }

  signOut() {
    // code for logout
    this.close();
    this.authService.signOut();
  }

  navigate(url: string) {
    this.close();
    this.router.navigate([url]);
  }

  getBasicUserServicesInfo() {
    this.profileService
      .getBasicUserServicesInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.services = res;
        this.hasFraudAccount = res.some((service) => service?.account?.flag_fraud);
      });
  }

  checkReportPermission() {
    if (!this.services) {
      return false;
    }
    for (const service of this.services) {
      for (const permission of service.permissions) {
        if (permission.permission_name === 'App.Soundblock.Account.Report.Payments') {
          return true;
        }
      }
    }
    return false;
  }

  async showNotification() {
    this.close();
    this.modalService.show(NotificationComponent, {
      class: 'modal-dialog-centered',
      ignoreBackdropClick: true,
      initialState: {},
    });
  }

  showTicketbar() {
    this.close();
    this.panelService.setTicketbarVisible(true);
  }

  close() {
    this.modalService.hide();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
